import Head from "next/head";
import styles from "../styles/Home.module.css";

const CustomHead = () => (
  <Head>
    <title>Taxi Bellinzona | Fast taxi</title>
    <meta name="description" content="Generated by create next app" />
    <link rel="icon" href="/favicon.ico" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta name="format-detection" content="telephone=no" />

    <meta
      name="robots"
      content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
    />
    <meta
      name="description"
      content="Serivzio di taxi a Bellinzona e Giubiasco, veicoli regolarmente igienizzati, chiamaci per maggiori informazioni sulle tariffe!"
    />
    <link rel="canonical" href="https://fast-taxi.ch/" />
    <meta property="og:locale" content="it_IT" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Taxi Bellinzona | Fast taxi" />
    <meta
      property="og:description"
      content="Taxi a Bellinzona e Giubiasco, veicoli regolarmente igienizzati, chiamaci per maggiori informazioni sulle tariffe!"
    />
    <meta property="og:url" content="https://fast-taxi.ch/" />
    <meta property="og:site_name" content="Taxi Bellinzona | Fast taxi" />
    <meta
      property="article:publisher"
      content="https://facebook.com/Fast-Taxi-Bellinzona-e-Giubiasco-103379514847463/"
    />
    <meta property="og:image" content="/IMG_6711.png" />
    <meta property="og:image:width" content="1080" />
    <meta property="og:image:height" content="1080" />
    <meta property="og:image:type" content="image/png" />
  </Head>
);

export default function Home() {
  const onClickCall = () => {
    setTimeout(() => {
      window.location = "tel:41767008161";
    }, 50);
  };

  return (
    <>
      <CustomHead />

      <div className={styles.firstBlock + " " + styles.bgOne}>
        <div className={styles.firstBlockContent + " pt-20 pb-20"}>
          <div className={"flex place-content-center"}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src={"/logo.png"}
              srcSet={"/logo2x.png 2x"}
              height={70}
              width={267}
              alt={"Torna alla home page"}
            />
          </div>

          <div className={"grid place-items-center mt-20"}>
            <div
              aria-label={"Chiama ora"}
              role={"button"}
              className={styles.callButton + " p-5 call-direct-phone"}
              onClick={onClickCall}
            >
              <div className={styles.number + " call-direct-phone"}>
                076 700 81 61
              </div>
              <div className={"call-direct-phone"}>
                Premi qui per chiamare ora!
              </div>
            </div>

            <div className={"m-3"}>oppure</div>

            <a
              href={
                "https://api.whatsapp.com/send/?phone=%2B41767008161&text&type=phone_number&app_absent=0"
              }
              className={
                "bg-green-600 hover:bg-green-700 mb-20 p-5 flex call-whatsapp"
              }
              aria-label={"Contattaci"}
              target={"_blank"}
              rel={"noreferrer"}
              style={{ color: "black", width: 244 }}
            >
              <svg
                className="w-6 h-6 fill-current mr-3"
                xmlns="https://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <title>WhatsApp</title>
                <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
              </svg>
              Scrivici su WhatsApp
            </a>
          </div>

          <h1 className="text-2xl font-bold">
            Fast Taxi bellinzona, tariffe low cost
          </h1>
        </div>
      </div>

      <div className={styles.secondBlock}>
        <h2 className={"text-3xl"}>I nostri servizi</h2>
        <p className={"mt-5"}>
          La nostra azienda offre un’ampia scelta di servizi di taxi a
          Bellinzona, Giubiasco e Arbedo.
        </p>

        <div className={"grid md:grid-cols-4 gap-4 mt-10 mb-10"}>
          <div className={"p-6 rounded-xl shadow-lg text-left"}>
            <p className={"text-2xl mb-3"}>Taxi - Corsa singola</p>
            <p>
              Chiama subito oppure scrivici tramite il nostro numero WhatsApp
              per prenotare la tua corsa con taxi Bellinzona.
            </p>
          </div>
          <div className={"p-6 rounded-xl shadow-lg text-left"}>
            <p className={"text-2xl mb-3"}>Taxi - Abbonamenti</p>
            <p>
              Se sei un cliente abituale puoi contattarci per richiedere un
              offerta per un abbonamento che ti permetterà di risparmiare
              ulteriormente sul costo del servizio taxi Bellinzona.
            </p>
          </div>
          <div className={"p-6 rounded-xl shadow-lg text-left"}>
            <p className={"text-2xl mb-3"}>Taxi - Autista privato</p>
            <p>
              Questo servizio offre un autista privato secondo le tue esigenze.
              Veicolo e autista sono a disposizione per viaggi locali o fuori
              paese. Il servizio è disponibile 24 ore su 24. Fast Taxi
              Bellinzona è una comoda soluzione per ogni tipo di viaggio.
            </p>
          </div>
          <div className={"p-6 rounded-xl shadow-lg text-left"}>
            <p className={"text-2xl mb-3"}>Taxi - Servizio aeroporti</p>
            <p>
              Su richiesta è disponibile il servizio “taxi privato” con
              destinazioni aeroportuali (tutta la svizzera e italia).
            </p>
          </div>
        </div>
      </div>

      <div className={styles.blackBlock + " p-20"}>
        <h2 className={"text-3xl mb-5"}>
          Approfitta subito delle nuove offerte.
        </h2>
        <p>
          Fast Taxi offre le migliori tariffe in circolazione, vantando di
          veicoli nuovi e ben curati. Contattaci subito per prenotare la tua
          corsa.
        </p>
        <p className={"mt-1"}>
          Chiamaci subito per riservare un taxi a Bellinzona, Giubiasco oppure
          ad Arbedo.
        </p>
      </div>

      <div className={"p-5 md:p-20"}>
        <h2 className={"text-3xl mb-5"}>Cosa dicono i nostri clienti</h2>
        <div className={"grid md:grid-cols-3 gap-20 mt-10 mb-10"}>
          <div className="max-w-4xl p-4 text-gray-800 rounded-xl shadow-lg">
            <div className="mb-2">
              <div className="h-3 text-3xl text-left text-gray-600">“</div>
              <p className="px-4 text-sm text-center text-gray-600">
                Chiamo regolarmente Fast Taxi Bellinzona per farmi portare al
                lavoro a Bellinzona. Svolgono un servizio eccellente, sempre
                puntuali, con veicoli nuovi e a prezzi vantaggiosi.
              </p>
              <div className="h-3 text-3xl text-right text-gray-600">”</div>
              <p className={"text-amber-800 mt-5"}>- Maurizio A.</p>
            </div>
          </div>

          <div className="max-w-4xl p-4 text-gray-800 rounded-xl shadow-lg">
            <div className="mb-2">
              <div className="h-3 text-3xl text-left text-gray-600">“</div>
              <p className="px-4 text-sm text-center text-gray-600">
                Mi è capitato di chiamare Fast Taxi Bellinzona in tarda serata e
                il servizio è davvero ottimo.
              </p>
              <p className="px-4 text-sm text-center text-gray-600 mt-2">
                Consiglio il serivizio per la sua affidabilità e puntualità.
              </p>
              <div className="h-3 text-3xl text-right text-gray-600">”</div>
              <p className={"text-amber-800 mt-5"}>- Irene M.</p>
            </div>
          </div>

          <div className="max-w-4xl p-4 text-gray-800 rounded-xl shadow-lg">
            <div className="mb-2">
              <div className="h-3 text-3xl text-left text-gray-600">“</div>
              <p className="px-4 text-sm text-center text-gray-600">
                Chiamo il taxi tutti i weekend per fare la spesa a Giubiasco.
                Sono sempre puntuali e i veicoli sempre puliti. L’autista porta
                sempre la mascherina ed è sempre disponibile un disinfettante
                per le mani.
              </p>
              <div className="h-3 text-3xl text-right text-gray-600">”</div>
              <p className={"text-amber-800 mt-5"}>- Claudio P.</p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.blackBlock + " p-20"}>
        <h2 className={"text-3xl mb-5"}>Chi siamo</h2>
        <p className={"font-bold"}>
          Fast Taxi Bellinzona, Giubiasco e Arbedo è un&apos;azienda che opera
          nel settore dei trasporti.
        </p>
        <p className={"mt-2"}>
          Tra i vari servizi offerti da Fast Taxi (Bellinzona, Giubiasco e
          Arbedo) vi sono: corse singole, corse con autista privato disponibile
          per viaggi locali o internazionali, servizio di abbonamento e
          destinazioni aeroportuali (Malpensa, Linate, Bergamo, Zurigo, ecc.).
        </p>
        <p className={"mt-2"}>
          Vista la situazione attuale, ai nostri preziosi clienti forniamo
          sempre vetture igienizzate e personale dotato di mascherine.
        </p>
      </div>

      <div className={"p-20"}>
        <h2 className={"text-3xl mb-5"}>Lo sapevi che ...?</h2>
        <p className={"mt-2"}></p>
        <p className={"mt-2"}>
          Il taxi è un servizio di trasporto che ha iniziato a essere utilizzato
          nel corso del XIX secolo. Inizialmente, i taxi erano carrozze trainati
          da due o più cavalli che potevano essere affittati per il trasporto di
          persone e/o merci da un luogo all&apos;altro. Con il progresso della
          tecnologia e l&apos;avvento dell&apos;automobile, i taxi sono
          diventati veicoli a motore che potevano essere guidati dagli autisti.
          I taxi sono generalmente considerati come un mezzo di trasporto più
          economico rispetto all&apos;utilizzo di un&apos;auto privata, poiché
          il costo del servizio è solitamente diviso tra i passeggeri.
        </p>
      </div>

      <div className={"bg-black text-white p-5 md:p-20 text-center"}>
        <p>Copyright 2022 www.fast-taxi.ch. Fast Taxi, 6500 Bellinzona</p>

        <div className={"flex gap-4 justify-center mt-5"}>
          <a
            rel={"noreferrer"}
            href={"https://www.facebook.com/fast.taxi.bellinzona/"}
            target={"_blank"}
          >
            <svg
              className="w-6 h-6 fill-current hover:text-blue-600"
              xmlns="https://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <title>Facebook</title>
              <path d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z" />
            </svg>
          </a>

          <a
            href={
              "https://api.whatsapp.com/send/?phone=%2B41767008161&text&type=phone_number&app_absent=0"
            }
            target={"_blank"}
            rel={"noreferrer"}
          >
            <svg
              className="w-6 h-6 hover:text-green-400 fill-current"
              xmlns="https://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <title>WhatsApp</title>
              <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
            </svg>
          </a>

          <a
            href={"https://www.instagram.com/fast.taxi.bellinzona/"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            <svg
              xmlns="https://www.w3.org/2000/svg"
              className="w-6 h-6 hover:text-green-400 fill-current"
              viewBox="0 0 16 16"
            >
              <title>Instragram</title>
              <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
            </svg>
          </a>
        </div>
      </div>
    </>
  );
}
